body,
html {
  width: 100%;
  height: 100%; }

body {
  font-family: 'Merriweather', 'Helvetica Neue', Arial, sans-serif; }

hr {
  max-width: 50px;
  border-width: 3px;
  border-color: #ddd253; }

hr.light {
  border-color: #fff; }

a {
  color: #a99e21;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s; }
  a:hover {
    color: #ddd253; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif; }

.bg-primary {
  background-color: #ddd253 !important; }

.bg-dark {
  background-color: #212529 !important; }

.text-faded {
  color: rgba(255, 255, 255, 0.7); }

section {
  padding: 8rem 0; }

.section-heading {
  margin-top: 0; }

::-moz-selection {
  color: #fff;
  background: #212529;
  text-shadow: none; }

::selection {
  color: #fff;
  background: #212529;
  text-shadow: none; }

img::selection {
  color: #fff;
  background: transparent; }

img::-moz-selection {
  color: #fff;
  background: transparent; }

body {
  -webkit-tap-highlight-color: #212529; }

#mainNav {
  border-bottom: 1px solid rgba(33, 37, 41, 0.1);
  background-color: #fff;
  width: 100%;
  -webkit-box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.64);
  box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.64);
  font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s; }
  #mainNav .navbar-brand {
    font-weight: 700;
    text-transform: uppercase;
    color: #ddd253;
    font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif; }
    #mainNav .navbar-brand .other {
      display: none; }
      @media (min-width: 992px) {
        #mainNav .navbar-brand .other {
          display: inline-block; } }
    #mainNav .navbar-brand .text {
      color: #ddd253; }
    #mainNav .navbar-brand:focus, #mainNav .navbar-brand:hover {
      color: #ddd253; }
  #mainNav .navbar-nav > li.nav-item > a.nav-link,
  #mainNav .navbar-nav > li.nav-item > a.nav-link:focus {
    font-size: .9rem;
    font-weight: 700;
    text-transform: uppercase;
    color: #212529; }
    #mainNav .navbar-nav > li.nav-item > a.nav-link:hover,
    #mainNav .navbar-nav > li.nav-item > a.nav-link:focus:hover {
      color: #ddd253; }
    #mainNav .navbar-nav > li.nav-item > a.nav-link.active,
    #mainNav .navbar-nav > li.nav-item > a.nav-link:focus.active {
      color: #a99e21 !important;
      background-color: transparent; }
      #mainNav .navbar-nav > li.nav-item > a.nav-link.active:hover,
      #mainNav .navbar-nav > li.nav-item > a.nav-link:focus.active:hover {
        background-color: transparent; }
  @media (min-width: 992px) {
    #mainNav {
      border-color: transparent;
      background-color: rgba(0, 0, 0, 0.5); }
      #mainNav .navbar-brand {
        color: rgba(255, 255, 255, 0.7); }
        #mainNav .navbar-brand:focus, #mainNav .navbar-brand:hover {
          color: #fff; }
      #mainNav .navbar-nav > li.nav-item > a.nav-link {
        padding: 0.5rem 1rem; }
      #mainNav .navbar-nav > li.nav-item > a.nav-link,
      #mainNav .navbar-nav > li.nav-item > a.nav-link:focus {
        color: rgba(255, 255, 255, 0.7); }
        #mainNav .navbar-nav > li.nav-item > a.nav-link:hover,
        #mainNav .navbar-nav > li.nav-item > a.nav-link:focus:hover {
          color: #fff; }
      #mainNav.navbar-shrink {
        border-bottom: 1px solid rgba(33, 37, 41, 0.1);
        background-color: white; }
        #mainNav.navbar-shrink .navbar-brand {
          color: #a99e21; }
          #mainNav.navbar-shrink .navbar-brand:focus, #mainNav.navbar-shrink .navbar-brand:hover {
            color: #ddd253; }
        #mainNav.navbar-shrink .navbar-nav > li.nav-item > a.nav-link,
        #mainNav.navbar-shrink .navbar-nav > li.nav-item > a.nav-link:focus {
          color: #212529; }
          #mainNav.navbar-shrink .navbar-nav > li.nav-item > a.nav-link:hover,
          #mainNav.navbar-shrink .navbar-nav > li.nav-item > a.nav-link:focus:hover {
            color: #ddd253; } }

header.masthead {
  padding-top: 10rem;
  padding-bottom: calc(10rem - 56px);
  background-image: url(../img/mercedes-inside.jpg);
  background-position: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; }
  header.masthead .container {
    padding: 45px;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 30px; }
  header.masthead hr {
    margin-top: 30px;
    margin-bottom: 30px; }
  header.masthead h1 {
    font-size: 2rem; }
  header.masthead p {
    font-weight: 300; }
  @media (min-width: 768px) {
    header.masthead p {
      font-size: 1.15rem; } }
  @media (min-width: 992px) {
    header.masthead {
      height: 100vh;
      min-height: 650px;
      padding-top: 0;
      padding-bottom: 0; }
      header.masthead h1 {
        font-size: 3rem; } }
  @media (min-width: 1200px) {
    header.masthead h1 {
      font-size: 4rem; } }

.service-box {
  max-width: 400px; }

.portfolio-box {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  margin: 0 auto; }
  .portfolio-box .portfolio-box-caption {
    position: absolute;
    bottom: 0;
    display: block;
    width: 100%;
    height: 100%;
    text-align: center;
    opacity: 0;
    color: #fff;
    background: rgba(221, 210, 83, 0.9);
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    transition: all 0.2s; }
    .portfolio-box .portfolio-box-caption .portfolio-box-caption-content {
      position: absolute;
      top: 50%;
      width: 100%;
      transform: translateY(-50%);
      text-align: center; }
      .portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-category,
      .portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-name {
        padding: 0 15px;
        font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif; }
      .portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-category {
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase; }
      .portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-name {
        font-size: 18px; }
  .portfolio-box:hover .portfolio-box-caption {
    opacity: 1; }
  .portfolio-box:focus {
    outline: none; }
  @media (min-width: 768px) {
    .portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-category {
      font-size: 16px; }
    .portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-name {
      font-size: 22px; } }

.text {
  color: #333; }

.fullback {
  width: 100%;
  min-height: 250px;
  height: 40vh;
  background-position: center;
  background-size: cover; }

#contact {
  margin-bottom: 100px; }

.callus {
  background: #ddd253;
  font-size: 15pt;
  position: fixed;
  bottom: 30px;
  right: 30px;
  padding: 20px;
  border: 3px #333 solid;
  border-radius: 50px;
  z-index: 1000; }
  .callus .icon {
    font-size: 40pt;
    margin: 0 10px; }
  .callus .number {
    display: none; }
    @media (min-width: 992px) {
      .callus .number {
        display: inline-block; } }
  .callus a {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333 !important;
    text-decoration: none; }

.taxi-img {
  max-width: 80%; }

.map {
  margin: auto;
  width: 90%;
  height: 50vh; }

.flag {
  height: 1em; }

.big {
  font-size: 40pt; }

.dropdown {
  cursor: pointer; }

.contacts a {
  color: inherit; }

.text-primary {
  color: #333 !important; }

.btn {
  font-weight: 700;
  text-transform: uppercase;
  border: none;
  border-radius: 300px;
  font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif; }

.btn-xl {
  padding: 1rem 2rem; }

.btn-primary {
  background-color: #ddd253;
  border-color: #ddd253;
  color: #333; }
  .btn-primary:hover, .btn-primary:focus, .btn-primary:active {
    color: #fff;
    background-color: #d9cc3e !important; }
  .btn-primary:active, .btn-primary:focus {
    box-shadow: 0 0 0 0.2rem rgba(240, 95, 64, 0.5) !important; }
